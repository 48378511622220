import React, { useEffect, useState } from 'react'
import './CenterPost.css'
import axios from '../../../../axios'
import { toast } from 'react-toastify';
import translations from '../../../../locales/translations.json';

function CenterPost({ unLikePost, likePost, posts, showPostModel, userData }) {

  const user = userData.user
  const token = localStorage.getItem('token')

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);
  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);


  const renderPosts = (feeds) => {

    return (feeds.length > 0 ?
      feeds.map((post, index) => {

        var options_id = 'options_img_' + post.public_id
        var close_id = 'close_options_' + post.public_id
        var optionsModalId = 'optionsModal_' + post.public_id
        return (
          <article className='app-element message relative-position' key={index} id={post.public_id}>
            <header className='app-element info header_options '>
              <img src={post.user.image ? post.user.image : '/profilePicture-default.png'} className='app-element profile-picture default-profile-picture' />
              <p className='app-element author'>{post.user.username}</p>
              <p className='app-element time'>{post.created_at}</p>
              {post.user.email === user.email && ( // Conditional rendering for options modal
                <div className="options_post">
                  <img src="/options.png" alt="Options" title='Options' id={options_id} className='options_img' onClick={() => toggleOptionsModal(post.public_id)} />
                  <p title='Close' id={close_id} className='close_options' onClick={() => toggleOptionsModal(post.public_id)} >&times;</p>
                </div>)}
            </header>
            <div className='app-element message-content' onClick={() => { showPostModel(post, post.public_id) }}>
              {post.text && <p>{post.text}</p>}
              {post.photo && <img src={post.photo} style={{ width: '100%', height: '100%' }} className='post-image' />}
              {post.audio && <audio src={post.audio} controls='true' />}
              {post.video && <video src={post.video} controls='true' />}
            </div>
            <div className='app-element like-comment'>
              <div className='app-element like-comment-button'>
                {post.liked ? <button onClick={() => { unLikePost(post.public_id) }}><i className='bx bxs-like' ></i></button> : <button onClick={() => { likePost(post.public_id) }}><i className='bx bx-like'></i></button>}
                <button onClick={() => { showPostModel(post, post.public_id, true) }}><i className='bx bx-comment'></i></button>
              </div>
              <p className='like-detail' onClick={() => { showPostModel(post, post.public_id, false, true) }}>{`${post.total_likes} likes`}</p>
              <p className='comment-detail cursor-pointer' onClick={() => { showPostModel(post, post.public_id, false, false, true) }}>{post.total_comments > 0 ? `${post.total_comments} comments` : `Be the First one to comment`}</p>
            </div>



            <div id={optionsModalId} className='optionsModal'>
              <div className="subOption" onClick={() => confirmDeletePost(post.public_id)}><img src="/delete-dark.png" alt="" /> <p>{t.delete} </p></div>
            </div>

          </article>
        )
      }) :
      <article className='app-element message'>{t.no_post}</article>
    )
  }

  function confirmDeletePost(public_id) {
    var modal = document.getElementById("deletePostModal");
    modal.style.display = "flex";
    document.getElementById('deletePostId').value = public_id;
  }

  function DeletePost() {
    var public_id = document.getElementById('deletePostId').value;

    const formData = new FormData();
    formData.append('token', token);
    formData.append('post', public_id);
    axios.post("/user/delete_post", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        document.getElementById(public_id).style.display = "none";
        closeModal();
        toast.success("Post Deleted Successfully");
      })
      .catch(err => {
        console.error(err);
        closeModal();
        toast.error("Post Deletion Failed");
      });

  }

  function toggleOptionsModal(public_id) {
    var modal = document.getElementById("optionsModal_" + public_id);
    if (modal.style.display === "block") {
      modal.style.display = "none";
      document.getElementById('options_img_' + public_id).style.display = "block"
      document.getElementById('close_options_' + public_id).style.display = "none"
    } else {
      modal.style.display = "block";
      document.getElementById('options_img_' + public_id).style.display = "none"
      document.getElementById('close_options_' + public_id).style.display = "block"
    }
  }

  function closeAllOptionsModal() {
    var modals = document.getElementsByClassName('optionsModal');
    for (let i = 0; i < modals.length; i++) {
      modals[i].style.display = "none";
    }
    var options = document.getElementsByClassName('options_img');
    for (let i = 0; i < options.length; i++) {
      options[i].style.display = "block";
    }
    var close_options = document.getElementsByClassName('close_options');
    for (let i = 0; i < close_options.length; i++) {
      close_options[i].style.display = "none";
    }
  }

  function closeModal() {
    document.getElementById('deletePostModal').style.display = 'none';
    closeAllOptionsModal();
  }

  useEffect(() => {

  }, [])

  return (
    <div className='scroll_edit'>
      <section className='app-element center invisible-scrollbar' id='post'>{renderPosts(posts)}</section>
      <div id="deletePostModal" className="post_modal">
        <div className="modal-content-post">
          <div className="modal-body">
            <p className="deleteText black-color">{t.post_delete}</p>
            <div className="yes_no_delete">
              <button id='yes_button' onClick={DeletePost}>{t.yes}</button>
              <button id='no_button' onClick={closeModal}>{t.no}</button>
            </div>
            <input type="hidden" name="deletePostId" id="deletePostId" readOnly />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CenterPost