import React, { useEffect, useState } from 'react'
import './EditProfile.css'
import axios from '../../../../axios'
import { toast } from 'react-toastify'
import translations from '../../../../locales/translations.json';

function EditProfile({ setEditProfile, user, refreshUser }) {
    const [upload, setUpload] = useState(false);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
      };
      const savedLanguage = getCookie('selectedLanguage');
      const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;
    
      const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
      const [t, setT] = useState(translations[currentLanguage]);
    
      useEffect(() => {
        // If no saved language, set English by default
        if (!savedLanguage) {
          document.cookie = `selectedLanguage=en; path=/`;
        }
    
        // Update translation based on the current language
        setT(translations[currentLanguage]);
      }, [currentLanguage, savedLanguage]);
    
    console.log(user)
    function stringToBoolean(str) {
        // Convert to lowercase for case-insensitive comparison
        str = String(str).toLowerCase();

        // Check for truthy values
        if (str === 'true' || str === '1' || str === 'yes' || str === 'on') {
            return true;
        }

        // Check for falsy values
        if (str === 'false' || str === '0' || str === 'no' || str === 'off' || str === '') {
            return false;
        }

        // If the string doesn't match any of the above, return null or throw an error
        return null; // or throw new Error('Invalid boolean string');
    }
    const [form, setForm] = useState({

        username: user.username || '',
        public: user.public || '',
        city: user.city || '',
        country: user.country || '',
        date_of_birth: user.date_of_birth || '',
        marital_status: user.marital_status || '',
        last_name: user.last_name || '',
        first_name: user.first_name || '',
        middle_name: user.middle_name || '',
        image: null,
        status: user.status || '',
        phone: user.phone || '',
        instagram: user.instagram || '',
        facebook: user.facebook || '',
        twitter: user.twitter || '',
        linkedin: user.linkedin || '',
        youtube: user.youtube || '',
        github: user.github || ''
    });

    const uploadImage = (file) => {
        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > 10) {
                toast.error("File size exceeds 10MB limit.");
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                document.getElementById('upload-image-preview').src = e.target.result;
            }
            reader.readAsDataURL(file);
        }
        setForm({ ...form, image: file });
    };

    const capitalize = (s) => {
        try {
            return s[0].toUpperCase() + s.slice(1);
        }
        catch {
            return 'False';
        }

    };

    const updateProfile = () => {
        const formData = new FormData();
        console.log(capitalize(form.public.toString()))
        formData.append('token', localStorage.getItem('token'));
        formData.append('username', form.username);
        formData.append('first_name', form.first_name);
        formData.append('middle_name', form.middle_name);
        formData.append('last_name', form.last_name);
        form.date_of_birth.length > 0 && formData.append('date_of_birth', form.date_of_birth);
        formData.append('public', capitalize(form.public.toString()));
        formData.append('city', form.city);
        formData.append('country', form.country);
        formData.append('marital_status', form.marital_status);
        formData.append('phone', form.phone);
        formData.append('status', form.status);
        formData.append('instagram', form.instagram);
        formData.append('facebook', form.facebook);
        formData.append('twitter', form.twitter);
        formData.append('linkedin', form.linkedin);
        formData.append('youtube', form.youtube);
        formData.append('github', form.github);

        form.image && formData.append('image', form.image);

        axios.post('/user/updateProfile', formData)
            .then(res => {
                setEditProfile(false);
                refreshUser();
                toast.success("Profile Updated Successfully");
            })
            .catch(err => {
                console.error(err);
                toast.error("Error during updating profile");
            });

        setUpload(false);
    };

    return (
        <section className='app-element center invisible-scrollbar'>
            <form className='app-element form' id='form_edit_profile' onSubmit={(e) => e.preventDefault()}>
                <header className='app-element title'>{t.edit_profile}</header>
                <div className='app-element profile-image' onClick={() => document.getElementById('upload-image').click()}>
                    <img
                        className='app-element profile-picture default-profile-picture'
                        src={user.image ? user.image : '/profilePicture-default.png'}
                        id='upload-image-preview'
                    />
                    <i className='bx bxs-edit edit-profile-picture'></i>
                    <input type='file' id='upload-image' accept='image/*' onChange={e => uploadImage(e.target.files[0])} hidden />
                </div>
                <div className='app-element public'>
                    <p id='private_text'>{t.private}</p>
                    <label className='app-element public'>
                        <input
                            type='checkbox'
                            className='app-element'
                            checked={stringToBoolean(form.public)}
                            onChange={e => setForm({ ...form, public: e.target.checked ? 'true' : 'false' })}

                        />
                        <span className="app-element fill"></span>
                    </label>
                    <p id='public_text'>{t.public}</p>
                </div>
                <input
                    placeholder={`${t.first_name}`}
                    className='app-element name'
                    value={form.first_name}
                    onChange={e => setForm({ ...form, first_name: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.middle_name}`}
                    className='app-element middle_name'
                    value={form.middle_name}
                    onChange={e => setForm({ ...form, middle_name: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.last_name}`}
                    className='app-element surname'
                    value={form.last_name}
                    onChange={e => setForm({ ...form, last_name: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.username}`}
                    className='app-element username'
                    value={form.username}
                    onChange={e => setForm({ ...form, username: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    type='text'
                    placeholder={`${t.date_of_birth}`}
                    className='app-element dob'
                    id='profile-edit-input'
                    onChange={e => setForm({ ...form, date_of_birth: e.target.value })}
                    onFocus={e => { e.target.type = 'date' }}
                    onBlur={e => { e.target.type = 'text' }}
                    value={form.date_of_birth}
                />
                <input
                    placeholder={`${t.instagram_acc}`}
                    className='app-element instagram'
                    value={form.instagram}
                    onChange={e => setForm({ ...form, instagram: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.facebook}`}
                    className='app-element facebook'
                    value={form.facebook}
                    onChange={e => setForm({ ...form, facebook: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.linkedin_acc}`}
                    className='app-element linkedin'
                    value={form.linkedin}
                    onChange={e => setForm({ ...form, linkedin: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.twitter}`}
                    className='app-element twitter'
                    value={form.twitter}
                    onChange={e => setForm({ ...form, twitter: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.youtube}`}
                    className='app-element youtube'
                    value={form.youtube}
                    onChange={e => setForm({ ...form, youtube: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.github}`}
                    className='app-element github'
                    value={form.github}
                    onChange={e => setForm({ ...form, github: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.city}`}
                    className='app-element city'
                    value={form.city}
                    onChange={e => setForm({ ...form, city: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.country}`}                    className='app-element country'
                    value={form.country}
                    onChange={e => setForm({ ...form, country: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.marital_status}`}
                    className='app-element m_s'
                    value={form.marital_status}
                    onChange={e => setForm({ ...form, marital_status: e.target.value })}
                    id='profile-edit-input'
                />
                <input
                    placeholder={`${t.phone}`}
                    className='app-element phone'
                    value={form.phone}
                    onChange={e => setForm({ ...form, phone: e.target.value })}
                    id='profile-edit-input'
                />
                <textarea
                    placeholder={`${t.bio}`}
                    className='app-element status profile-edit-textarea'
                    value={form.status}
                    onChange={e => setForm({ ...form, status: e.target.value })}
                    id='profile-edit-input'
                />
                <button className='app-element cancel' onClick={() => setEditProfile(false)}>{t.cancel}</button>
                <button className='app-element save' onClick={() => updateProfile()} disabled={upload}>{upload ? t.saving : t.save }</button>
            </form>
        </section>
    );
}

export default EditProfile;
